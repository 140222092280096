import React, { FunctionComponent } from 'react';
import SOPSearch from '../components/sop-search';
  
const SOPRech: FunctionComponent = () => {
  
  return (
   <div>
      <div className="container"> 
        <SOPSearch />
       </div>
    </div> 
  );
}
  
export default SOPRech; 