import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import "./sop-accueil.css";


  
const SOPAccueil: FunctionComponent = () => {
  
  return (
    <div className="center">
      <div className="accueil">
      <h1 className="red-text text-lighten-2">Répertoire des rues de Paris</h1>  
      <img className="responsive-img" src="./assets/images/pvp680.jpg" alt="Bienvenue" />
      </div>
      <Link className="btn-floating btn-large waves-effect waves-light red lighten-2 z-depth-3"
        style={{position: 'fixed', top: '75px', left: '25px'}}
        to="/SOP/rech">
        <i className="material-icons">search</i>
      </Link>
      © copyright 2024 |&nbsp;
      <Link to="/SOP/mentions" className="red-text">Mentions légales</Link>
    </div>
  );
}

export default SOPAccueil;