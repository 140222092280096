import React, { FunctionComponent }  from 'react';
import { Link } from 'react-router-dom';
import './sop-sidebar.css';

type Props = {
    displaySidebar: boolean
  };

const SOPSidebar: FunctionComponent<Props> = ({displaySidebar}) => {

   return (

       
    <div>
    { displaySidebar && 
    <div className = "sidebar">       
    <ul>
    <li><Link className="white-text" to="/">Accueil</Link></li>
    <li><Link className="white-text" to="/SOP/rech">Recherche</Link></li>
    <li><Link className="white-text" to="/SOP/contact">Contact</Link></li>
    </ul>
    </div> 
    } 
    </div>

    )
}
  
export default SOPSidebar;