import Voie from "../models/voie";
import VOIES from '../models/voies.json';

export default class SOPService {

  static voies:Voie[] = VOIES;

  static isDev = false; 

  static getVoies(): Promise<Voie[]> {
    if(this.isDev) {
      return fetch('http://localhost:3001/voies')
      .then(response => response.json())
      .catch(error => this.handleError(error));
    }

    return new Promise(resolve => {
      resolve(this.voies);
    });
  }

  static getVoie(identifiant: number): Promise<Voie|undefined> {
    if(this.isDev) {
      return fetch(`http://localhost:3001/voies/${identifiant}`)
      .then(response => response.json())
      .then(data => this.isEmpty(data) ? undefined : data)
      .catch(error => this.handleError(error));
    }

    return new Promise(resolve => { 
      const results = this.voies.find(voie => identifiant === voie.identifiant)  
      resolve(results);
    }); 
  }

  static searchVoie(term: string): Promise<Voie[]> {
    if(this.isDev) {
      return fetch(`http://localhost:3001/voies?q=${term}`)
      .then(response => response.json())
      .catch(error => this.handleError(error));
    }

    return new Promise(resolve => {    
      const results = this.voies.filter(voie => voie.denomination.includes(term));
      resolve(results);
    });

  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}