import React, { FunctionComponent, useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import Voie from '../models/voie';
import SOPService from '../services/sop-services';
import Loader from '../components/loader';
import SOPMap from '../components/sop-map';
import extractGeo from '../helpers/extractGeo';
 
type Params = { id: string };
  
const SOPDetail: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
    
  const [voie, setVoie] = useState<Voie|undefined>(undefined);

  const [urlWiki] = useState<string>("https://fr.wikipedia.org/wiki/"); 
  
  const [isMapDisp, setIsMapDisp] = useState<boolean>(true);
  
  const handleToggleMap = () => setIsMapDisp(!isMapDisp);
  
  useEffect(() => {
    SOPService.getVoie(+match.params.id).then(voie => setVoie(voie));
  }, [match.params.id]);
    
  return (
    <div>
      { voie ? (
        <div className="row">
          <div className="col s12 m8 offset-m2"> 
            <h2 className="header center">{ voie.denomination }</h2>
            <div className="card hoverable"> 
              <div className="card-stacked">
                <div className="card-content">
                <Link  className="black-text" to="/SOP/rech"><i className="material-icons">close</i></Link>
                  <table className="bordered striped">
                    <tbody>
                      <tr> 
                        <td>Nom</td> 
                        <td><strong>{ voie.denominationmin }</strong></td> 
                      </tr>
                      <tr> 
                        <td>Arrondissement</td> 
                        <td><strong>{ voie.arrondissement }</strong></td> 
                      </tr> 
                      <tr> 
                        <td>Quartier</td> 
                        <td><strong>{ voie.quartier }</strong></td> 
                      </tr> 
                      <tr> 
                        <td>Origine</td> 
                        <td><strong>{ voie.origine }</strong></td> 
                      </tr> 
                      <tr> 
                        <td>Historique</td> 
                        <td><strong>{ voie.historique }</strong></td> 
                      </tr> 
                    </tbody>
                  </table>
                  <a className="red-text text-lighten-2" href={urlWiki.concat(voie.denominationmin.replaceAll(' ', '_'))} rel="noreferrer" target="_blank">lien vers Wikipédia</a>
                  <div className="center">
                  { isMapDisp ? (
                  <span className="burger" onClick={() => handleToggleMap()}>masquer le plan</span>
                  ) : (
                  <span className="burger" onClick={() => handleToggleMap()}>afficher le plan</span>  
                  )
                  }
                  </div>
                  { isMapDisp &&
                  <SOPMap lat={extractGeo(voie.geopoint2d)[0]} long={extractGeo(voie.geopoint2d)[1]} bulle={voie.denominationmin} />}
                  </div>
                <div className="card-action">
                  <Link  className="red-text text-lighten-2" to="/SOP/rech">Retour</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h4 className="center"><Loader/></h4>
      )}
    </div>
  );
}
  
export default SOPDetail;