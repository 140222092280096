import React, { FunctionComponent } from 'react';
import { TileLayer , MapContainer , Marker, Popup  } from "react-leaflet"
import { LatLngTuple} from "leaflet";

type Props = {
  lat: number,
  long: number,
  bulle: string
};

const SOPMap: FunctionComponent<Props> = ({lat,long,bulle}) => {

    //const MAP_CENTER: LatLngTuple = [44.4268, 26.1025];
    const MAP_CENTER: LatLngTuple = [lat,long]; 
    const MAP_ZOOM = 16;
return (
        <div>
            <MapContainer
            center={MAP_CENTER}
            zoom={MAP_ZOOM}
            style={{ height: "100vh" }}
            >
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={MAP_CENTER}>
            <Popup>
              {bulle}
            </Popup>
           </Marker>
           </MapContainer>
        </div>   

);
}

export default SOPMap;