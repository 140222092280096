import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import "./sop-mentions.css";
  
const SOPMentions: FunctionComponent = () => {
  
  return (
    <div>  
      <div className="mentions">  
      <h4 className="red-text text-lighten-2">Mentions légales</h4>
      <span>
      Site récréatif non professionnel et non marchand hébergé par <a className="red-text text-lighten-2" href="https://firebase.google.com/support" rel="noreferrer" target="_blank">FIREBASE Hosting (Google)</a>.<br/>
      Pour toutes questions, requêtes ou réclamations, vous pouvez contacter Hervé par courriel à l’adresse suivante : herveviaparis@gmail.com
      </span>
      <h4 className="red-text text-lighten-2">Propriété intellectuelle</h4>
      <span>
      Le contenu de ce site (textes, images, vidéos, etc.) est la propriété exclusive des auteurs. Toute reproduction ou utilisation sans autorisation préalable est strictement interdite.
      A noter que les données concernant les voies de Paris sont issues en partie du fichier « Nomenclature officielle des voies de Paris » mis à disposition par la Ville de Paris sur le site <a className="red-text text-lighten-2" href="https://opendata.paris.fr" rel="noreferrer" target="_blank">opendata.paris.fr</a>. 
      </span>
      <h4 className="red-text text-lighten-2">Données personelles</h4>
      <span>
      Ce site ne collecte aucune donnée personnelle (adresse IP, géolocalisation, ...).
      </span>
      <h4 className="red-text text-lighten-2">Cookies</h4>
      <span>
      Ce site n'utilise pas de cookies.
      </span>
      <h4 className="red-text text-lighten-2">Liens externes</h4>
      <span>
      Ce site peut contenir des liens vers des sites tiers. Nous déclinons toute responsabilité quant au contenu de ces sites externes.
      </span>
      <h4 className="red-text text-lighten-2">Droit applicable</h4>
      <span>
      Ce site est soumis au droit français.
      </span>
      </div>
      <div className="center">
      <Link to="/" className="waves-effect waves-teal btn-flat">
        Retourner à l'accueil
      </Link>
      </div>
     </div>
  );
}

export default SOPMentions;