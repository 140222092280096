import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import './sop-search.css';
import Voie from '../models/voie';
import SOPService from '../services/sop-services';
import removeAccents from '../helpers/removeAccents';


const SOPSearch: FunctionComponent = () => {
 
  const [term, setTerm] = useState<string>('');
  const [voies, setVoies] = useState<Voie[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const term = e.target.value;
    setTerm(term);

    if(term.length <= 1) {
      setVoies([]);
      return;
    }

    SOPService.searchVoie(removeAccents(term).toUpperCase()).then(voies => setVoies(voies));
    
  }
 
  return (
    <div className="row"> 
        <div className="input-field col s12">
        <i className="material-icons prefix">search</i>  
        <input type="text" autoFocus placeholder="Rechercher une voie de Paris." value={term} onChange={e => handleInputChange(e)} /> 
        </div>
          <div className="collection">
          {voies.map((voie) => (
          <Link key={voie.identifiant} to={`/SOP/${voie.identifiant}`} className="collection-item red-text text-light-3">
            {voie.denomination} {voie.arrondissement}
          </Link>
          ))}
        </div>
    </div>
  );
}
 
export default SOPSearch;