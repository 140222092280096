import React, { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
  
const SOPMenu: FunctionComponent = () => {
  
  const history = useHistory();
  const closeMenu = () =>
    {
      history.goBack();
    }

  return (
     <div className="row">
         <div className="col s12 m8 offset-m2">
         <h2 className="header center">menu</h2> 
            <div className="card hoverable"> 
              <div className="card-stacked">
                <div className="card-content">
                <div onClick={() => closeMenu()}><i className="material-icons">close</i></div>
                <div className="card-action">
                  <Link  className="red-text text-lighten-2" to="/">Accueil</Link>
                  <Link  className="red-text text-lighten-2" to="/SOP/rech">Rechercher</Link>
                  <Link  className="red-text text-lighten-2" to="/SOP/contact">Contact</Link>
                </div>
                <div className="card-action center">
                <span className="red-text text-lighten-2"><FaFacebook  /></span> &nbsp;
                <span className="red-text text-lighten-2"><FaInstagram /></span> &nbsp;
                <span className="red-text text-lighten-2"><FaTwitter /></span> &nbsp;
                </div>
              </div>
            </div>
          </div>
          </div>
    </div>
  );
}
  
export default SOPMenu;