import React, { FunctionComponent, useState }  from 'react';
import SOPAccueil from './pages/sop-accueil';
import SOPMenu from './pages/sop-menu';
import SOPContact from './pages/sop-contact';
import SOPMentions from './pages/sop-mentions';
import SOPRech from './pages/sop-rech';
import SOPDetail from './pages/sop-detail';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageNotFound from './pages/page-not-found';
import SOPSidebar from './components/sop-sidebar';
import "./App.css";

const App: FunctionComponent = () => {

  const [isSidebarDisp, setIsSidebarDisp] = useState<boolean>(false);
  
  const handleToggleSidebar = () => setIsSidebarDisp(!isSidebarDisp);

 return (
    <Router>
    <div>
       {/* Barre de navigation */}
    <nav> 
      <div className="nav-wrapper red lighten-2">
        <Link to="/" className="brand-logo center">via Paris</Link>
        <ul className="left">
           <li>
           { isSidebarDisp ? (
           <span className="burger" onClick={() => handleToggleSidebar()}><i className="material-icons">close</i></span>
           ) : (
           <span className="burger" onClick={() => handleToggleSidebar()}><i className="material-icons">menu</i></span>
           )}
           {/*<Link to="/SOP/menu" ><i className="material-icons left">menu</i></Link>*/} 
           </li>
         </ul>
      </div> 
    </nav>
    <SOPSidebar displaySidebar={isSidebarDisp} />
    {/* Systeme de gestion des routes */}
    <Switch>
      <Route exact path="/" component={SOPAccueil} />
      <Route exact path="/SOP" component={SOPAccueil} />
      <Route exact path="/SOP/menu" component={SOPMenu} />
      <Route exact path="/SOP/rech" component={SOPRech} />
      <Route exact path="/SOP/contact" component={SOPContact} />
      <Route exact path="/SOP/mentions" component={SOPMentions} />
      <Route path="/SOP/:id" component={SOPDetail} />
      <Route component={PageNotFound} />
    </Switch>
    </div>
    </Router>
 )
}
  
export default App;